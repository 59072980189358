import { Button, Container, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useTranslation } from "react-i18next";
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import Badges from '../components/circularBadges/Badges';
import { Link } from 'react-router-dom';

export default function Services() {
    const { t } = useTranslation()
    return (
        <>
            <Breadcrumb />
            <Box className="switch-theme-section">
                <Container>
                    <Box className="page-content">
                        <Box className="page-content-image">
                            <img
                                className="levitating-image"
                                src="/assets/images/hands-moon.png"
                                alt="Moons positions"
                                style={{ maxWidth: '100%', height: "auto" }}
                            />
                        </Box>
                        <Box className="page-content-text">
                            <Stack spacing={3.5} sx={{ display: 'flex', flexDirection: 'column', alignItems: { xs: 'center', md: 'flex-start' }, md: { xs: 4 }, mt: '1rem' }}>
                                <Typography variant="h6" fontWeight="fontWeightBold" color="text.illustration" textTransform='uppercase'>
                                    {t('home_4')}
                                </Typography>
                                <Typography variant="h3" fontWeight="fontWeightBold" color="#1a1818">{t('home_5')}</Typography>
                                <Typography variant="body" fontWeight="fontWeightMedium" color="#777777">{t('home_6')}</Typography>
                                <Button component={Link} to="https://wa.me/9256354424" variant="contained" size="large" sx={{ py: 2, px: 2 }}>
                                    <Typography fontWeight="fontWeightBold">Consult on Whatsapp</Typography>
                                </Button>
                            </Stack>
                        </Box>
                    </Box>
                    <Badges />
                </Container>
            </Box>
        </>
    )
}
