import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

export default function NotFound() {
    const navigate = useNavigate()
    useEffect(() => {
        setTimeout(() => {
            navigate("/")
            navigate(-1)
        }, 5000)
    }, [])
    return (
        <Box sx={{width:'360px', margin:'auto'}}>
            <Typography variant="h3" mb={4}>Not Found</Typography>
            <img src="/assets/images/404.svg" alt="Not found" width="100%"></img>
        </Box>
    )
}
