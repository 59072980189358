
export const formatDateToDdMmYy = (date) =>{
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'Asia/Kolkata' // Use the time zone for IST
  };
  
  return date.toLocaleString('en-IN', options);
}