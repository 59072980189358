import React from 'react'
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
// import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import InfoCard from '../components/cards/InfoCard';
import HoroscopeDetails from '../components/horoscopeDetails/HoroscopeDetails';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import './styles.scss';

// const Item = styled(Paper)(({ theme }) => ({
//     backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//     ...theme.typography.body2,
//     padding: theme.spacing(1),
//     textAlign: 'center',
//     color: theme.palette.text.secondary,
//     overflow: 'hidden'
// }));

export default function Home() {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const scrollToForm = () => {
        const element = document.getElementById("inputForm");
        if(element) {
            window.scrollTo(0, document.body.scrollHeight);
        }
    };
    const routeToServices = () => {
        navigate('/services');
    }
    const services = ["Kundali", "Vastu", "Matchmacking", "Palm"];
    // const facts = [{ number: "800K", text: "Lorem ipsum Bairh" }, { number: "18", text: "Years of Experience" }, { number: "58", text: "Vastu Construction" }];
    return (
        <Container>
            <section>
                <Grid container spacing={2} sx={{ flexDirection: { xs: 'column-reverse', md: 'row' }, mt: 1, textAlign: { xs: 'center', sm: 'left' } }}>
                    <Grid item xs={12} md={6}>
                        <Stack spacing={3.5} sx={{ display: 'flex', flexDirection: 'column', alignItems: { xs: 'center', md: 'flex-start' }, md: { xs: 4 }, mt: { xs: '2rem', md: '3rem' } }}>
                            <Typography variant="h6" fontWeight="fontWeightBold" color="text.illustration" textTransform='uppercase'>
                                {t('home_1')}
                            </Typography>
                            <Typography variant="h2" fontWeight="fontWeightBold" color="text.primary">{t('home_2')}</Typography>
                            <Typography variant="body" fontWeight="fontWeightMedium" color="text.secondaryText">{t('home_3')}</Typography>
                            <Button variant="contained" size="large" sx={{ py: 2, px: 2 }} onClick={scrollToForm}>
                                <Typography fontWeight="fontWeightBold">Book Appointment Now</Typography>
                            </Button>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box className="home-head-image">
                            <img
                                className="rotating-image"
                                src="/assets/images/zodic-wheel-min.png"
                                alt="zodic-wheel"
                                style={{ width: '100%', height: "auto" }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </section>
            {/* <section>
                <Box sx={{
                    display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around',
                    mt: { xs: 4, md: 8, lg: 10 }, p: { xs: 2, md: 4, lg: 6 }, backgroundColor: "#f0efe9", borderRadius: '0.5rem'
                }} >
                    {facts.map((fact, index) => (
                        <Box key={index} xs={12} sm={4} sx={{ flexGrow: { xs: 1, sm: 0 }, p: 2 }}>
                            <Typography variant="h3" fontWeight={700} color="black">{fact.number}</Typography>
                            <Typography variant="subtitle2" color="black" fontWeight={600}>{fact.text}</Typography>
                        </Box>
                    ))}
                </Box>
            </section> */}
            <section>
                <Grid container spacing={2} sx={{ mt: { xs: 6, md: 12, lg: 16 }, textAlign: { xs: 'center', sm: 'left' } }}>
                    <Grid item xs={12} md={6} sx={{ textAlign: { xs: 'ceneter', md: 'left' } }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', margin: { xs: 'auto', md: 'inherit' }, width: { xs: '70%', lg: '90%' } }}>
                            <img
                                src="/assets/images/photo.png"
                                alt="hand lines"
                                style={{ maxWidth: '100%', height: "auto" }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack spacing={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: { xs: 'center', md: 'flex-start' }, md: { xs: 4 }, mt: { xs: '2rem', md: '3rem' } }}>
                            <Typography variant="h6" fontWeight="fontWeightBold" color="text.illustration" textTransform='uppercase'>
                                {t('home_4')}
                            </Typography>
                            <Typography variant="h3" fontWeight="fontWeightBold" color="text.primary">{t('home_5')}</Typography>
                            <Typography variant="body" fontWeight="fontWeightMedium" color="text.secondaryText" align='left'>{t('home_6')}</Typography>
                            <Box>
                                <Button variant="contained" size="large" sx={{ py: 2, px: 2, mr:2, mt:1 }} onClick={scrollToForm}>
                                    <Typography fontWeight="fontWeightBold">Book Appointment Now</Typography>
                                </Button>
                                <Button variant="outlined" size="large" sx={{ py: 2, px: 2, mr:2 , mt:1}} onClick={routeToServices}>
                                    <Typography fontWeight="fontWeightBold">View More</Typography>
                                    {/* <span className='levitating-arrow'> */}
                                        <KeyboardArrowRightRoundedIcon fontSize="medium" />
                                    {/* </span> */}
                                </Button>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </section>
            <section>
                <Box container spacing={2} sx={{ mt: { xs: 6, md: 12, lg: 16 }, position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                    <StarRoundedIcon fontSize="large" />
                    <Stack spacing={2}>
                        <Typography variant="h6" fontWeight="fontWeightBold" color="text.illustration" textTransform='uppercase'>
                            {t('home_7')}
                        </Typography>
                        <Typography variant="h3" fontWeight="fontWeightMedium" color="text.secondaryText">{t('home_8')}</Typography>
                    </Stack>
                    <Grid container spacing={3} sx={{ mt: { xs: 4, sm: 6, md: 8 }, position: 'relative' }}>
                        {
                            services.map((service, i) => (
                                <Grid key={i} item xs={12} sm={6} md={4}>
                                    <InfoCard key={i} service={service} />
                                </Grid>
                            ))
                        }
                    </Grid>
                </Box>
            </section>

            <section>
                <Box sx={{ mt: { xs: 6, md: 12, lg: 16 } }}  id='inputForm'>
                    <Typography variant="h6" fontWeight="fontWeightBold" color="text.illustration" textTransform='uppercase'>
                        {t('home_9')}
                    </Typography>
                    <HoroscopeDetails />
                </Box>
            </section>
        </Container>
    )
}
