export const contactLinks = ["Contact Us", "Services", "Article", "About"];
export const navItems = [
    { route: "/", display: "Home" },
    { route: "services", display: "Services" },
    // { route: "Article", display: "Article" },
    // { route: "about", display: "About" },
    // { route: "contact-us", display: "Contact Us" },
];

export const trustUs = [{
    title: "Privacy Gaureented",
    description: "All consultations and personal information are treated with confidentiality, ensuring a secure and trusted experience.",
    image: "/assets/images/secret.png"
},
{
    title: "Business/Job/Career",
    description: "Unlock success in your career and business endeavors. Excel in your professional journey and the most of every opportunity.",
    image: "/assets/images/eye-kundali.png"
}, {
    title: "Relations and finance",
    description: "Achieve stability in both personal and financial matters. Build strong relationships and sound financial choices",
    image: "/assets/images/hands-planets.png"
}
, {
    title: "Gemstones and Crystals",
    description: "Get energized Gemstones and Crystals for positive energy and healing, all available at the best prices",
    image: "/assets/images/triangular.png"
}, {
    title: "Clarity In Life",
    description: "Discover a way out of confusion, bring peace to your life with confidence, growth and happiness",
    image: "/assets/images/planet-eye.png"
}]

export const blogData = [
    {
        title: "Sun Enters in Makar Rashi And Its Effects",
        author: "Dr. Sheela Khandelwal",
        date: "April 09, 2023",
        image: ""
    },
    {
        title: "Sun Enters in Makar Rashi And Its Effects",
        author: "Dr. Sheela Khandelwal",
        date: "April 09, 2023",
        image: ""
    }
]