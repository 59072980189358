import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { trustUs } from '../../utils/common-data';
import '../styles.scss';

export default function Badges(props) {

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            {
                trustUs.map((trust, index) => (
                    <Box key={index} sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', width: '245px', p: 2.5, m: 2, borderRadius: '0.3rem' }}>
                        <Box>
                            <img src={trust.image} alt={trust.title}></img>
                        </Box>
                        <Stack spacing={2}>
                            <Typography variant="h6">{trust.title}</Typography>
                            <Typography variant="subtitle2" align='center'>{trust.description}</Typography>
                        </Stack>
                    </Box>
                ))
            }
        </Box>
    )
}
