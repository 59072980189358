import { useTranslation } from "react-i18next";
import {  useLocation, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Container } from '@mui/material';
import '../styles.scss';

export default function Breadcrumb() {
    const location = useLocation()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const routeNesting = location.pathname.split('/');
    const handleClick = (event) => {
        event.preventDefault();
        navigate('/', { replace: true });
    }
    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/" onClick={handleClick}>
            {t('Home')}
        </Link>,
        <Typography key="3" color="text.primary" textTransform='capitalize'>
            {t(routeNesting[routeNesting.length - 1])}
        </Typography>,
    ];

    return (
        <Container sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
            <Typography variant="h3" textTransform='capitalize' fontWeight={600} sx={{ p: { xs: 2, md: 3 } }}>{t(routeNesting[routeNesting.length - 1])}
            </Typography>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
            >
                {breadcrumbs}
            </Breadcrumbs>
        </Container>
    )
}
