// import { useTranslation } from "react-i18next";
import { Routes, Route, useLocation, Link } from 'react-router-dom';
import { useLayoutEffect } from 'react';
// import useMediaQuery from '@mui/material/useMediaQuery';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useMemo } from "react";
import { amber, grey } from '@mui/material/colors';
import NotFound from "./pages/404";
// import About from "./pages/About";
import Blog from "./pages/Blog";
import Home from "./pages/Home";
import Services from "./pages/Services";
import NavBar from "./components/navBar/NavBar";
import { Box } from "@mui/material";
import './App.css';

import Footer from "./components/footer/Footer";

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    }
    );
  }, [location.pathname]);
  return children
}

const getDesignTokens = (mode) => {
  // console.log('MODE :', mode)
  return ({
    typography: {
      h1: {
        fontFamily: 'philosopher',
        letterSpacing: '1px'
      },
      h2: {
        fontFamily: 'philosopher',
        letterSpacing: '1px'
      },
      h3: {
        fontFamily: 'philosopher',
        letterSpacing: '1px'
      },
      // h4: {
      //   fontFamily: 'Playfair Display',
      //   letterSpacing: '1px'
      // },
      // h5: {
      //   fontFamily: 'Playfair Display',
      //   letterSpacing: '1px'
      // },
      // h6: {
      //   fontFamily: 'Playfair Display',
      //   letterSpacing: '1px'
      // },
      fontFamily: [
        'Open Sans',
        'sans-serif',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
    palette: {
      mode,
      primary: {
        ...amber,
        ...(mode === 'dark' && {
          main: amber[300],
        }),
      },
      ...(mode === 'dark' && {
        background: {
          default: "#1F2126",
          paper: "#1F2126",
          secondary: "#323539",
          dark: "#1a1818"
        },
      }),
      text: {
        ...(mode === 'light'
          ? {
            primary: grey[900],
            secondary: grey[800],
          }
          : {
            primary: '#F0F0F0',
            accent: '#FF7F00',
            illustration: '#b08450',
            secondaryText: '#B1B1B1'
          }),
      },
      success: {
        ...(mode === 'light'
          ? {
            main: grey[900],
            light: grey[800],
          }
          : {
            main: '#3CC13B'
          }),
      },
      warning: {
        ...(mode === 'light'
          ? {
            main: grey[900],
            light: grey[800],
          }
          : {
            main: '#F5A623'
          }),
      },
      error: {
        ...(mode === 'light'
          ? {
            main: grey[900],
            light: grey[800],
          }
          : {
            main: '#F44336'
          }),
      },
    }
  })
};

function App() {
  // const { t } = useTranslation();
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  let theme = useMemo(
    () =>
      // createTheme(getDesignTokens(prefersDarkMode ? 'dark' : 'light')),
      createTheme(getDesignTokens('dark')),
    [],
  );
  theme = responsiveFontSizes(theme);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <NavBar />
        <Box sx={{
          backgroundImage: { xs: "url(/assets/images/astrology-head-mob.png)", md: "url(/assets/images/astrology-head-web.png)" },
          height: "100vh",
          backgroundPosition: "bottom center",
          backgroundSize: "cover",
          opacity: "0.15",
          transition: "background 0.3s, border- radius 0.3s, opacity 0.3s",
          position: "absolute",
          top: 0,
          width: '100%',
          backgroundColor: "#21212C78",
          zIndex: -1
        }}>
        </Box>
        <Box component="main" sx={{ pt: { xs: 2, sm: 3, md: 5 }, pb: 2, position: 'relative' }}>
          <Link key='whatsapp' className="float-whatsapp" to="https://wa.me/9256354424" target="_blank">
            <img src="/assets/images/whatsapp-icon.png" alt="Whatsapp" width="100%"></img>
          </Link>
          <Wrapper>

            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/services">
                <Route index element={<Services />} />
                <Route path=":id" element={<Services />} />
              </Route>
              <Route path="/blog">
                <Route index element={<Blog />} />
                <Route path=":id" element={<Blog />} />
              </Route>
              {/* <Route path="/about" element={<About />} /> */}
              {/* <Route path="/contact-us" element={<Contact />} /> */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Wrapper>
        </Box>

        <Footer />
      </ThemeProvider>
    </div >
  );
}

export default App;
