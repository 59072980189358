import { Box, Paper, Typography } from '@mui/material'
import React from 'react'
import { blogData } from '../../utils/common-data';

export default function BlogCard() {
    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
            {blogData.map((blog, index) =>

                <Paper className="blog-card" key={blog.title}>
                    <Box className="img-container">
                        <img src="https://unsplash.it/1000/600/?plantes&pic={index}" alt="random-pic" style={{ maxWidth: '100%', height: "auto" }} />
                        <span className='blog-card-date'>April 9, 2023</span>
                    </Box>
                    <Typography variant="h5">{blog.title}</Typography>
                </Paper>
            )}
        </Box>
    )
}
