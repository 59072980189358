import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import CallToAction from '../callToAction/CallToAction';
import '../styles.scss';
import { Container } from '@mui/material';

const drawerWidth = 240;
const navItems = [
    { route: "/", display: "Home", replace: true },
    { route: "services", display: "Services", replace: false },
    // { route: "blog", display: "Blog", replace: false },
    // { route: "about", display: "About", replace: false },
    // { route: "contact-us", display: "Contact Us", replace: false },
];

function NavBar(props) {
    const { t } = useTranslation();
    const { window } = props;
    const navigate = useNavigate()
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const routeToHome = () => {
        navigate('/', { replace: true });
    }

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Box sx={{ my: 2, cursor: 'pointer' }} onClick={routeToHome}>
                <img src="/assets/images/logo.png" alt="Dr. Sheela Khandelwal" width="50px"></img>
            </Box>
            <List>
                {navItems.map((item, index) => (
                    <ListItem key={index} disablePadding>
                        <ListItemButton>
                            {/* <ListItemIcon>
                                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                            </ListItemIcon> */}
                            <Link className="nav-link" to={item.route}>
                                <ListItemText primary={t(item.display)} />
                            </Link>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box >
            <CssBaseline />
            <AppBar component="nav" sx={{ position: 'relative' }} >
                <CallToAction />
                <Toolbar variant="regular">
                    <Container sx={{ display: 'flex'}}>
                        <Box
                            sx={{ flexGrow: 1, display: { xs:'flex',sm: 'block' }, alignItems:'center', width:{ xs: '210px', sm:'250px', md: '270px'}, textAlign: 'left', cursor: 'pointer' }}
                            onClick={routeToHome}
                        >
                           <img src="/assets/images/logo-with-name.png" alt="Dr. Sheela Khandelwal" style={{width:"inherit"}}></img>
                        </Box>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="end"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { sm: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Box sx={{ display: { xs: 'none', sm: 'block', margin:'auto'  } }}>
                            {
                                navItems.map((item, index) => {
                                    return (
                                        item.route === '/' ?
                                            // JUST FOR SAMPLE, USING CSS .active CLASS NOW
                                            // <NavLink className="nav-link" style={({ isActive }) => {
                                            //     return isActive ? { color: "#FF7F00" } : {}
                                            // }} to={item.route} replace>
                                            //     {t(item.display)}
                                            // </NavLink>
                                            <NavLink key={index} className="nav-link" to={item.route} replace>
                                                {t(item.display)}
                                            </NavLink>
                                            :
                                            <NavLink key={index} className="nav-link" to={item.route} >
                                                {t(item.display)}
                                            </NavLink>
                                    )
                                }
                                )}
                        </Box>
                    </Container>
                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    anchor={'right'}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </Box >
    );
}

NavBar.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default NavBar;