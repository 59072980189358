import { Button, Grid, InputAdornment, Paper, Stack, TextField, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import '../styles.scss';
import { checkValidity } from '../../utils/check-validity';
import _ from "lodash";
import CallRoundedIcon from '@mui/icons-material/CallRounded';
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
import Snackbar from '@mui/material/Snackbar';
import { formatDateToDdMmYy } from '../../utils/date-format';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function HoroscopeDetails() {
    const emailInput = useRef(null);
    const mobileInput = useRef(null);
    const fullNameInput = useRef(null);
    const messageInput = useRef(null);
    
    const [open, setOpen] = useState({
        openSnackbar: false,
        vertical: 'bottom',
        horizontal: 'center',
      });

    const { vertical, horizontal, openSnackbar } = open;
    const [fullNameValid, setFullNameValid] = useState([true, ""]);
    const [phoneValid, setPhoneValid] = useState([true, ""]);
    const [emailValid, setEmailValid] = useState([true, ""]);

    const saveDetails = (userData)=>{
        fetch(process.env.REACT_APP_SHEET_DB_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_SHEETDB_TOKEN}`
            },
            body: JSON.stringify({
                data: [
                    userData
                ]
            })
        })
        .then((response) => response.json())
        // .then((data) => console.log(data))
        .then(setOpen({...open, openSnackbar: true }))
        .catch(err=>{
            console.error('Error:', err);
        })
    
    }

    const onSubmitHandler = (event) => {
        event.preventDefault();
        let fullName_ = checkValidity(_.capitalize(fullNameInput.current.value), "required");
        let emailValid_ = checkValidity(_.toLower(_.trim(emailInput.current.value)), "email");
        let phone_ = checkValidity(_.toLower(_.trim(mobileInput.current.value)), "phoneNumber");

        setFullNameValid(fullName_);
        setEmailValid(emailValid_);
        setPhoneValid(phone_);
        let userData ={}
        const currentDate = new Date();
        if (
            fullName_[0] &&
            emailValid_[0] &&
            phone_[0]
        ) {
            userData = {
                date: formatDateToDdMmYy(currentDate),
                name: _.capitalize(fullNameInput.current.value),
                email: _.toLower(_.trim(emailInput.current.value)),
                phone: _.toLower(_.trim(mobileInput.current.value)),
                query: _.trim(messageInput.current.value)
            };
            emailInput.current.value=null
            mobileInput.current.value=null
            fullNameInput.current.value=null
            messageInput.current.value=null
            saveDetails(userData);
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen({ ...open, openSnackbar: false });
      };

    return (
        <Stack spacing={2} className="horoscope-detail">

            <Typography variant='h4' component='div' fontWeight="600" textTransform='capitalize'>Take the first step forward towards success!</Typography>
            <Typography variant='subtitle1' component='div' fontWeight="500">Fill your  details here, and book your appointment Now!!</Typography>
            <Paper sx={{ p: { xs: 3, sm: 4, md: 6 } }}>
                <form id="contactForm" name="sentMessage" noValidate onSubmit={onSubmitHandler}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                required
                                fullWidth
                                name="Full Name"
                                label="Full Name"
                                variant="outlined"
                                inputRef={fullNameInput}
                                helperText={fullNameValid[0] ? "" : fullNameValid[1]}
                                error={!fullNameValid[0]}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Person2RoundedIcon size="22" /></InputAdornment>,
                                }}
                                inputProps={{
                                    maxLength: 25
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>

                            <TextField
                                required
                                fullWidth
                                type="tel"
                                name="mobile"
                                maxLength="10"
                                label="Phone (Whatsapp)"
                                inputRef={mobileInput}
                                helperText={phoneValid[0] ? "" : phoneValid[1]}
                                error={!phoneValid[0]}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><CallRoundedIcon size="22" /> +91</InputAdornment>,
                                }}
                                inputProps={{
                                    maxLength: 10
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>

                            <TextField
                                fullWidth
                                name="email"
                                type="email"
                                label="Email"
                                variant="outlined"
                                inputRef={emailInput}
                                helperText={emailValid[0] ? "" : emailValid[1]}
                                error={!emailValid[0]}
                                inputProps={{
                                    maxLength: 30
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                type="textarea"
                                name="query"
                                multiline
                                rows={3}
                                label="Your Query"
                                variant="outlined"
                                inputRef={messageInput}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        sx={{ width: "150px", alignSelf: "center", mt: 4 }}
                        variant="contained"
                    >
                        Book Now
                    </Button>
                </form>
            </Paper>
            <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ vertical, horizontal }}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                Details Saved! We will contact you shortly
                </Alert>
            </Snackbar>
        </Stack>
    )
}
