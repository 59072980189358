import { useTranslation } from "react-i18next";
import {
    Box,
    Container,
    IconButton,
} from "@mui/material";
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import '../styles.scss';
import { makePhoneCall, sendEmail } from "../../utils/functions";

export default function CallToAction() {
    const { t } = useTranslation();
    return (
        <Box sx={{ backgroundColor: "background.dark", zIndex: 2, display:{xs:'none',sm:'block'}}}>
            <Container direction="row" sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: "space-between", py: 1 }}>
                <Box sx={{ mr: 2, my: 0.5 }} className="action-circle-button" onClick={sendEmail}>
                    <IconButton sx={{ mr: 1 }} aria-label="Contact Email" size="medium" href="mailto:sheelaastrologer@gmail.com">
                        <MarkEmailUnreadOutlinedIcon fontSize="small" />
                    </IconButton>
                    sheelaastrologer@gmail.com
                </Box>
                <Box sx={{ mr: 1, my: 0.5 }} className="action-circle-button" onClick={makePhoneCall}>
                    <IconButton sx={{ mr: 1 }} aria-label="Call" size="medium" href="tel:+919256354424">
                        <CallOutlinedIcon fontSize="small" />
                    </IconButton>
                    +(91) 9256354424
                </Box>
                <Box sx={{ my: 0.5, flexGrow: { xs: 0, sm: 1 }, textAlign: "right" }} className="action-circle-button">
                    <IconButton aria-label="Timing" size="medium">
                        <AccessTimeOutlinedIcon fontSize="medium" />
                    </IconButton>
                    11:00 {t('AM')} - 6:30 {t('PM')}
                </Box>
            </Container>
        </Box>
    )
}
