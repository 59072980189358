export const checkValidity = (value, type) => {
    switch (type) {
        case "password": {
            if (value) {
                // const pattern = /(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
                const pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
                // if (!pattern.test(value)) return [false, 'A password should contain minimum eight characters, at least one letter and one number'];
                if (!pattern.test(value))
                    return [
                        false,
                        "Password must be more than 8 characters, upper case letter,at least one numeric digit and a special character",
                    ];
            } else {
                return [false, "Please enter a password"];
            }
            return [true, ""];
        }
        case "time": {
            if (value) {
                let timeValidation = /^([1-9]|0[1-9]|1[0-2]):[0-5][0-9] ([AaPp][Mm])$/;
                return timeValidation.test(value);
            } else {
                return false;
            }
        }
        case "required": {
            if (!value) {
                return [false, "This field is required"];
            }
            return [true, ""];
        }
        case "number": {
            if (!value) {
                return [false, "This field is required"];
            } else if (value < 0) {
                return [false, "Value must be a positive integer"];
            }
            return [true, ""];
        }
        case "pos_number": {
            if (!value) {
                return [false, "This field is required"];
            } else if (value <= 0) {
                return [false, "Value must be a positive integer"];
            }
            return [true, ""];
        }
        case "email": {
            if (value) {
                const pattern = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,}$/;
                if (!pattern.test(value))
                    return [false, "Please enter a valid Email address"];
            }
            return [true, ""];
        }
        case "pincode": {
            if (value) {
                const pattern = /^[1-9][0-9]{5}$/;
                if (!pattern.test(value))
                    return [false, "Please enter a valid Pin Code"];
            } else {
                return [false, "Please enter Pin code"];
            }
            return [true, ""];
        }
        case "phoneNumber": {
            if (value) {
                const pattern = /^[0-9]{10}$/;
                if (!pattern.test(value))
                    return [false, "Please enter a valid Phone Number"];
            } else {
                return [false, "Please enter Phone Number"];
            }
            return [true, ""];
        }
        default: {
            return [true, ""];
        }
    }
};

  // let timeValidation = new RegExp(/([1-9]|0[1-9]|1[0-2]):[0-5][0-9] ([AaPp][Mm])$/);
