import { Paper } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from "react-i18next";

export default function InfoCard(props) {
    const { t } = useTranslation();
    const translateimage = props.service + ".image";
    const translateservice = props.service + ".service";
    const translatedescription = props.service + ".description";
    return (
        <Paper className="info-card">
            <Stack spacing={2.5} className="info-card-body">
                <Box className="img-container">
                    <img src={t(translateimage)} alt={t(translateservice)} />
                </Box>
                <Typography variant="subtitle1" fontSize={'20px'}>{t(translateservice)}</Typography>
                <Typography variant="body2">{t(translatedescription)}</Typography>
            </Stack>
        </ Paper>
    )
}
