export const sendEmail = (email = process.env.REACT_APP_CONTACT_EMAIL) => {
    // console.log('EMAIL :', email);
    const subject = "Guide me !";
    const body = "My question is : ";

    const mailtoLink = `mailto:${email}?subject=${subject}&body=${body}`;
    window.location.href = mailtoLink;
}

export const makePhoneCall = (phoneNo = process.env.REACT_APP_CONTACT_PHONE) => {
    // console.log('PHONE :', phoneNo);
    window.location.href = `tel:${phoneNo}`;
}