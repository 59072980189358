// import { useParams } from 'react-router-dom';
// import { useTranslation } from "react-i18next";
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import BlogCard from '../components/blogCard/BlogCard';
import { Container } from '@mui/material';

export default function Blog() {
    // const { t } = useTranslation()
    // const { id } = useParams()
    return (
        <>
            <Breadcrumb />
            <Container>

                <BlogCard />
            </Container>
        </>
    )
}
