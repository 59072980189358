import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { makePhoneCall, sendEmail } from '../../utils/functions';
import '../styles.scss';
import { navItems } from '../../utils/common-data';

export default function Footer() {
    const { t } = useTranslation();
    return (
        <Box className="footer">
            <Box className="img-container">
                <img src="/assets/images/astrology-footer-web.png" alt="astrology-illustration" />
            </Box>
            <Container>
                <Grid container spacing={2}>
                    <Grid item className="content-section" xs={12} md={4}>
                        <Box sx={{ px: 2, py: 1 }}>
                            <Typography variant='h3'>{t("Sheela")}</Typography>
                            <Typography variant='h3'>{t("Khandelwal")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item className="content-section" xs={12} md={4}>
                        <Stack spacing={2} p={2}>
                            <Typography className="content-title" variant="h6">{t('footer_quick_links')}</Typography>
                            {navItems.map((link, index) => <Link key={index} className="nav-link" to={link.route}>{t(link.display)}</Link>)}
                        </Stack>
                    </Grid>
                    <Grid item className="content-section" xs={12} md={4}>
                        <Stack spacing={3} p={2}>
                            <Typography className="content-title" variant="h6">{t('footer_contact_info')}</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', mr: 2, my: 0.5 }} className="action-circle-button" onClick={sendEmail}>
                                <IconButton sx={{ mr: 1 }} aria-label="Contact Email" size="medium" href="mailto:sheelaastrologer@gmail.com">
                                    <MarkEmailUnreadOutlinedIcon fontSize="small" />
                                </IconButton>
                                {process.env.REACT_APP_CONTACT_EMAIL}
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', mr: 1, my: 0.5 }} className="action-circle-button" onClick={makePhoneCall}>
                                <IconButton sx={{ mr: 1 }} aria-label="Call" size="medium" href="tel:+919256354424">
                                    <CallOutlinedIcon fontSize="small" />
                                </IconButton>
                                {process.env.REACT_APP_CONTACT_PHONE}
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', mr: 1, my: 0.5, textAlign: "right" }} className="action-circle-button">
                                <IconButton aria-label="Timing" size="medium">
                                    <AccessTimeOutlinedIcon fontSize="large" />
                                </IconButton>
                                <Box>
                                    <Typography textAlign={'left'}>Timings: </Typography>
                                    11:00 {t('AM')} - 6:30 {t('PM')}
                                </Box>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
